<!-- [X] change privacy acordian title to "Privacy" -->
<!-- [X] center on page -->

<template>
	<div v-if="loading" :value="loading" class="absolute inset-0 grid place-items-center">
		<div class="flex flex-col items-center">
			<img src=".\assets\ball-triangle.svg" alt="svg loading animation" />
		</div>
	</div>
	<!-- spinner provided by https://samherbert.net/svg-loaders/ -->
	<main class="container mx-auto -mt-16 max-w-3xl" v-bind="$attrs" v-else>
		<div class="flex flex-col px-8">
			<div class="relative rounded-lg bg-white p-10 text-center shadow-lg">
				<div v-if="step !== 1 && step !== 4" class="absolute top-6 left-8 flex">
					<button class="btn-ghost px-2 text-3xl text-neutral-400 transition-colors hover:text-neutral-800"
						@click="goHome">
						<i class="la la-home"></i>
					</button>
				</div>
				<div class="mt-1 mb-8 flex justify-center">
					<div
						class="select-none rounded-md p-1 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-400 focus-visible:ring-offset-2">
						<img class="h-14" src="@/assets/supporters-stor-transparent.png" alt="supporters logo" />
					</div>
				</div>
				<h1 class="text-3xl font-bold">{{ txt.title }}</h1>
				<p class="mt-4" v-if="invalidCaseID">{{ txt.invalidCaseID }}</p>
				<p v-else-if="isUsed">{{ txt.linkUsed }}</p>
				<div class="pt-12" v-else>
					<Config v-if="step == 1" @useRecord="useRecord" @useFile="useFile" @setMic="setMic" />
					<File v-if="step == 2 && isFile" :mic="mic" @next="hasFile" @updateWarning="setShouldWarn"
						@restart="step = 1" />
					<Record v-if="step == 2 && !isFile" :mic="mic" @updateWarning="setShouldWarn" @next="step2next"
						@back="step--" />
					<Review v-if="step == 3" :caseID="caseID" :blob="blob" :webM="webM" :number="number" @upload="step++"
						@updateWarning="setShouldWarn" @back="step--" @restart="step = 1" />
					<Upload v-if="step == 4" :caseID="caseID" :number="number" :blob="blob" :file="file" :isFile="isFile"
						:fileList="fileList" />
				</div>
			</div>
			<div class="relative px-6 pt-8 text-sm">
				<div tabindex="-1" class="w-auto pb-2 text-xl">
					<button @click="handlePrivacyClick"
						class="flex select-none flex-row rounded-md transition-colors hover:text-primary-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-400 focus-visible:ring-offset-4 focus-visible:ring-offset-gray-100">
						<h3>{{ txt.privacyTitle }}</h3>
						<i class="la la-angle-down ml-4 text-xl transition-transform"
							:style="privacyShown ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }"></i>
					</button>
				</div>
				<div class="absolute pb-8 transition-[clip-path]"
					:style="!privacyShown ? { 'clip-path': 'inset(0 0 100% 0)' } : { 'clip-path': 'inset(0 0 -20px 0)' }">
					<p v-html="txt.privacyText" />
					<br />
					<a class="link" :href="txt.privacyLink.href">{{ txt.privacyLink.text }}</a>
				</div>
			</div>
		</div>
		<ConfirmModal ref="confirmModal" />
	</main>
</template>

<script>
import Config from "./pages/config.vue";
import Record from "./pages/record.vue";
import File from "./pages/file.vue";
import Review from "./pages/review.vue";
import Upload from "./pages/upload.vue";
import ConfirmModal from "./components/ConfirmModal.vue";

export default {
	components: { Config, Record, Review, Upload, File, ConfirmModal },
	data() {
		return {
			isUsed: false,
			loading: true,
			stopLoading: false,
			shouldWarn: false,
			mic: false,
			file: null,
			fileList: null,
			isFile: false,
			blob: null,
			webM: null,
			step: 1,
			privacyShown: false,
		};
	},
	async mounted() {
		document.title = this.txt.tabTitle;

		await this.delay(25);
		//wait for the check to complete
		await this.checkAvailable();

		//sequence for loading animation
		setTimeout(() => (this.stopLoading = true), 500);
		setTimeout(() => (this.loading = false), 1200);
	},
	computed: {
		caseID() {
			//extract the caseID from path
			return window.location.pathname.split("/")[1];
		},
		number() {
			//extract the number from after the caseID
			return window.location.pathname.split("/")[2];
		},
		invalidCaseID() {
			var casePattern = /^[a-zA-Z0-9]{2,}-\d{6}-\d{1,3}$/;
			var numberPattern = /^[0-9]{1,}$/;
			//if pattern does NOT match, mark invalid
			return !casePattern.test(this.caseID) || !numberPattern.test(this.number);
		},
	},
	methods: {
		async goHome() {
			if (this.shouldWarn) {
				const ok = await this.$refs.confirmModal.show();
				console.log(ok);
				if (ok) {
					this.step = 1;
					this.setShouldWarn(false);
				}
			} else {
				this.step = 1;
			}
		},
		goBack() {
			if (this.step !== 1) {
				this.step--;
				if (this.step === 1) {
					this.setShouldWarn(false);
				}
			}
		},
		handlePrivacyClick() {
			this.privacyShown = !this.privacyShown;
		},
		useFile() {
			this.isFile = true;
			console.log("useFile");
			this.step++;
		},
		useRecord() {
			console.log("useRecord");
			this.isFile = false;
			this.step++;
		},
		hasFile(file, fileList) {
			console.log("hasFile", file, fileList);
			this.file = file;
			this.fileList = fileList;
			this.step++;
			this.step++;
		},
		setShouldWarn(state) {
			console.log("updateWarning", state);
			this.shouldWarn = state;
		},
		setMic(mic) {
			console.log("Microphone usage:", mic);
			this.mic = mic;
		},
		async checkAvailable() {
			let request = await this.axios.get(`${this.backendUrl}/isUsed?caseID=${this.caseID}&number=${this.number}`, {
				headers: {
					"x-functions-key": "pvJQxLKT71TFyg1oi8unNCUtfFYjnOmJ5OabMCidWtKTAzFueOxM1w==",
				},
			});
			console.log(request.data);
			//if not available, mark as in use
			if (request.data === 1) {
				this.isUsed = true;
			}
		},
		step2next(blob, webM) {
			console.log("blob/webM recieved", blob, webM);
			this.blob = blob;
			this.webM = webM;
			this.step++;
		},
	},
};
</script>
