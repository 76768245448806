<!-- [X] clearer remove button -->
<!-- [X] add file is orange and upload is gray if no files, else add files is gray and upload is orange if files -->
<!-- [X] send btn is disabled if no files -->

<template>
	<div class="flex items-center justify-center">
		<div class="flex w-full flex-col" v-if="!preparing">
			<div v-if="files.length === 0">
				<h3 class="text-xl font-bold">{{ txt.noFilesAddedTitle }}</h3>
				<p class="pt-2">{{ txt.noFilesAddedText }}</p>
			</div>
			<table v-else class="px-8">
				<thead>
					<tr>
						<th class="col-1 px-4 py-2"></th>
						<th class="col-2 px-4 py-2">
							<span
								class="flex cursor-pointer items-center text-gray-500 transition-colors hover:text-gray-800"
								:class="{ 'text-gray-800': sortBy === 'name' }" data-sort-by="name"
								@click="handleSortClick">Name<i class="la la-sort-alpha-down ml-2 text-xl"></i>
							</span>
						</th>
						<th class="col-3 px-4 py-2">
							<span
								class="flex cursor-pointer items-center text-gray-500 transition-colors hover:text-gray-800"
								:class="{ 'text-gray-800': sortBy === 'type' }" data-sort-by="type"
								@click="handleSortClick">Type<i class="la la-sort-alpha-down ml-2 text-xl"></i>
							</span>
						</th>
						<th class="col-4 px-4 py-2">
							<span
								class="flex cursor-pointer items-center text-gray-500 transition-colors hover:text-gray-800"
								:class="{ 'text-gray-800': sortBy === 'size' }" data-sort-by="size"
								@click="handleSortClick">Size<i class="la la-sort-alpha-down ml-2 text-xl"></i>
							</span>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(file, i) in files" :key="i">
						<td class="col-1 cursor-pointer p-2 transition-colors hover:text-primary-400"
							@click="removeFile(i)">
							<i class="la la-close text-xl"></i>
						</td>
						<td class="col-2 px-4 py-2 text-start">{{ getFileName(file) }}</td>
						<td class="col-3 px-4 py-2 text-start">{{ getFileType(file) }}</td>
						<td class="col-4 px-4 py-2 text-start">{{ getFileSize(file) }}</td>
					</tr>
				</tbody>
			</table>
			<div class="flex flex-row justify-end gap-8 px-4 pt-10">
				<button :class="{ 'btn-primary': files.length === 0, 'btn-ghost': files.length !== 0 }" @click="addFile">
					<div class="-ml-2 pr-2">
						<i class="la la-download text-2xl" />
					</div>
					{{ txt.btnAddFile }}
				</button>
				<button :disabled="files.length === 0"
					:class="{ 'btn-ghost': files.length === 0, 'btn-primary': files.length !== 0 }" @click="upload">
					<div class="-ml-2 pr-2">
						<i class="la la-send text-2xl" />
					</div>
					{{ txt.btnSend }}
				</button>
			</div>
		</div>
		<div class="flex w-full flex-col" v-else>
			<h3 class="text-loader pb-4 text-2xl">{{ txt.preparing }}</h3>
		</div>
	</div>
</template>

<script>
const JSZip = require("jszip");
export default {
	data() {
		return {
			files: [],
			sortAsc: true,
			prevSortAsc: true,
			preparing: false,
			sortBy: "name",
			prevSortBy: "name",
		};
	},
	methods: {
		getFileName(file) {
			return file.name.substr(0, file.name.lastIndexOf("."));
		},
		getFileType(file) {
			// set some custom filetypes wherever relevant
			const typeMap = new Map();
			typeMap.set("png", this.txt.imageType);
			typeMap.set("jpg", this.txt.imageType);
			typeMap.set("jpeg", this.txt.imageType);

			// return custom filetype if applicable ...
			const extractedFileType = file.name.substr(file.name.lastIndexOf(".") + 1);

			if (typeMap.has(extractedFileType)) {
				return typeMap.get(extractedFileType);
			}
			// ... otherwise just infer filetype from name
			return extractedFileType.toUpperCase();
		},
		getFileSize(file) {
			return this.formatSize(file.size);
		},
		formatSize(size, decimalPoint = 2) {
			// takes the raw bytesize of a file as input
			// and converts it to a more human readable number with correct suffix
			const sizes = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

			const k = 1000;
			const i = Math.floor(Math.log(size) / Math.log(k));

			if (size === 0) {
				return `${size} ${sizes[0]}`;
			}

			return `${parseFloat((size / Math.pow(k, i)).toFixed(decimalPoint))} ${sizes[i]}`;
		},
		addFile() {
			let input = document.createElement("input");
			input.type = "file";
			input.multiple = true;
			input.onchange = (e) => {
				let newFiles = e.target.files;
				console.log("change", newFiles);
				this.files = [...this.files, ...newFiles];
				this.sortTable(this.prevSortBy, this.prevSortAsc);
				console.log("allFiles", this.files);
				this.$emit("updateWarning", this.files.length > 0);
			};
			input.click();
		},
		removeFile(index) {
			this.files.splice(index, 1);
			this.$emit("updateWarning", this.files.length > 0);
		},
		handleSortClick(ev) {
			const listenerEl = ev.currentTarget;
			const listenerIcon = listenerEl.querySelector("i.la");

			if (this.sortBy === listenerEl.getAttribute("data-sort-by")) {
				this.flipIcon(listenerIcon);
				this.sortTable(listenerEl.getAttribute("data-sort-by"));
			} else {
				this.resetIcons();
				this.sortTable(listenerEl.getAttribute("data-sort-by"), true);
			}
		},
		flipIcon(icon) {
			if (icon.classList.contains("la-sort-alpha-down")) {
				icon.classList.remove("la-sort-alpha-down");
				icon.classList.add("la-sort-alpha-up");
			} else if (icon.classList.contains("la-sort-alpha-up")) {
				icon.classList.remove("la-sort-alpha-up");
				icon.classList.add("la-sort-alpha-down");
			}
		},
		resetIcons() {
			const header = document.querySelector("thead");
			const icons = header.querySelectorAll("i.la");

			for (let i = 0; i < icons.length; i++) {
				const icon = icons[i];
				if (icon.classList.contains("la-sort-alpha-up")) {
					icon.classList.remove("la-sort-alpha-up");
					icon.classList.add("la-sort-alpha-down");
				}
			}
		},
		sortTable(sortBy, sortAsc = this.sortAsc) {
			this.prevSortBy = sortBy;
			this.prevSortAsc = sortAsc;
			this.sortBy = sortBy;
			if (sortAsc === true) {
				if (sortBy === "type") {
					// extrapolate type from the name attribute instead of using the built in type attribute
					this.files.sort((x, y) => (x["name"].substr(x["name"].lastIndexOf(".") + 1) < y["name"].substr(x["name"].lastIndexOf(".") + 1) ? -1 : 1));
					this.sortAsc = false;
				} else {
					this.files.sort((x, y) => (x[sortBy] < y[sortBy] ? -1 : 1));
					this.sortAsc = false;
				}
			} else if (sortAsc === false) {
				if (sortBy === "type") {
					// extrapolate type from the name attribute instead of using the built in type attribute
					this.files.sort((x, y) => (x["name"].substr(x["name"].lastIndexOf(".") + 1) < y["name"].substr(x["name"].lastIndexOf(".") + 1) ? -1 : 1));
					this.sortAsc = false;
				} else {
					this.files.sort((x, y) => (x[sortBy] > y[sortBy] ? -1 : 1));
					this.sortAsc = true;
				}
			}
		},
		async upload() {
			if (this.files.length === 0) {
				window.alert("Please add one or more files before uploading!");
				// } else if (this.files.length === 1) {
				//     // dont zip if only one file was uploaded
			} else {
				this.preparing = true;
				let list = [];
				let zip = new JSZip();
				for (let i = 0; i < this.files.length; i++) {
					const file = this.files[i];
					zip.file(file.name, file, {
						compression: "STORE"
					});
					list.push({ name: file.name, size: file.size });
				}
				console.log(zip);
				const final = await zip.generateAsync({ type: "blob" });
				this.$emit("next", final, list);
				this.preparing = false;
			}
		},
	},
};
</script>
