<!-- TODO -->
<!-- [X] find better checkmark -->

<template>
	<div class="justify-center text-center">
		<div v-if="error">
			<h3 class="pb-4 text-2xl">{{ txt.uploadErrorTitle }}</h3>
			<p>{{ txt.uploadErrorText }}</p>
		</div>
		<div v-else>
			<div v-if="uploading">
				<div>
					<h3 class="text-loader pb-4 text-2xl">{{ txt.uploadingTitle }}</h3>
					<div class="px-6">
						<div class="h-6 w-full rounded-full bg-gray-400 bg-opacity-20">
							<div class="h-full rounded-full bg-primary-400" :style="`width: ${progress}%`"></div>
						</div>
					</div>
				</div>
			</div>
			<div v-else>
				<h3 class="mb-4 text-2xl">{{ txt.uploadedTitle }}</h3>
				<div class="mb-8">
					<i class="la la-check-circle text-6xl"></i>
				</div>
				<p v-if="!isFile">
					{{ txt.uploadedRecordingText }}
				</p>
				<p v-else-if="fileList.length === 1">
					{{ txt.uploadedFileText }}
				</p>
				<p v-else-if="fileList.length > 1">
					{{ txt.uploadedFilesText }}
				</p>
				<p>{{ txt.closeThisPage }}</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ["blob", "caseID", "number", "isFile", "file", "fileList"],
	data() {
		return {
			progress: 0,
			uploading: true,
			error: false,
		};
	},
	async mounted() {
		try {
			//send to azure functions to get a key, with specified caseID
			let uploadData = await this.axios.get(`${this.backendUrl}/getTempWriteKey?caseID=${this.caseID}&number=${this.number}`, {
				headers: {
					"x-functions-key": "pvJQxLKT71TFyg1oi8unNCUtfFYjnOmJ5OabMCidWtKTAzFueOxM1w==",
				},
			});

			//if backend failed to provide an API key
			if (uploadData.data == null) {
				alert(this.text.unknownError);
				return;
			}

			var uploadURL = uploadData.data.uploadUrl;
			var authorizationToken = uploadData.data.authorizationToken;

			let data = null;
			let type = null;
			let names = null;
			if (!this.isFile) {
				console.log("Uploading in video mode", this.file);
				//determine filetype by extracting between "/" and ";"
				var filetype = this.blob.type.match(/^(.*)\/(.*);/)[2];
				data = this.blob;
				type = this.blob.type;
			} else {
				console.log("Uploading in file mode", this.file);
				filetype = "zip";
				data = this.file;
				type = this.file.type;

				let strings = [];
				for (let i = 0; i < this.fileList.length; i++) {
					const file = this.fileList[i];
					strings.push(`${file.name}|${file.size}`);
				}
				names = encodeURIComponent(strings.join(","));
			}

			//enter next stage

			let upload = await this.axios({
				url: uploadURL,
				data: data,
				method: "POST",
				headers: {
					Authorization: authorizationToken,
					"X-Bz-File-Name": `${this.caseID}_${this.number}.${filetype}`, //name the file with correct fileformat
					"Content-Type": type,
					"X-Bz-Content-Sha1": "do_not_verify",
					"X-Bz-Info-Files": names,
					// "X-Bz-Info-Files": "t"
				},
				timeout: 10 * 60 * 1000, // 10 minutes
				onUploadProgress: (progressEvent) => {
					//calculate percentage on every update of the progress
					this.progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
				},
			});

			this.uploading = false;

			this.axios.get(`${this.backendUrl}/uploadDone?caseID=${this.caseID}&number=${this.number}`, {
				headers: {
					"x-functions-key": "pvJQxLKT71TFyg1oi8unNCUtfFYjnOmJ5OabMCidWtKTAzFueOxM1w==",
				},
			});

			console.log(upload);
		} catch (error) {
			this.error = true;
			console.log(error);
		}
	},
	methods: {},
};
</script>
