<!-- TODO -->
<!-- [X] useRecord() -->
<!-- [X] txt.start -->
<!-- [X] useMic -->
<!-- [X] center usemic toggle -->
<!-- [X] txt.mic -->
<!-- [X] useFile() -->
<!-- [X] txt.uploadFile -->

<template>
	<div class="mx-auto flex max-w-fit flex-col pb-4">
		<div class="flex flex-row justify-center gap-8">
			<button
				class="group relative block w-full select-none rounded-lg border-2 border-dashed border-gray-300 px-6 pt-5 pb-6 text-center transition-colors hover:border-gray-400 hover:text-primary-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-400 focus-visible:ring-offset-2"
				@click="useRecord()"
				type="button"
			>
				<div class="transition-transform group-hover:scale-105">
					<div class="mx-auto h-12 w-12">
						<svg
							class="ml-0.5 h-12 w-12"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 48 48"
							stroke="currentColor"
							stroke-width="2"
							fill="none"
							aria-hidden="true"
						>
							<path
								class="ml-2"
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M31,21 l9,-9 a1.5,1.5 0 0 1 2.5,1 v22.75 a1.5,1.5 0 0 1 -2.5, 1 l-9,-9 M8.5,37.5 h18 a4.5 4.5 0 0 0 4.5,-4.5 v-18 a4.5 4.5 0 0 0 -4.5,-4.5 h-18 A4.5,4.5 0 0 0 4.5,15 v18 a4.5,4.5 0 0 0 4.5 4.5z"
							/>
						</svg>
					</div>
					<span class="text-md mt-2 block text-inherit"> {{ txt.startRecording }} </span>
				</div>
			</button>
			<div class="select-none self-center font-bold text-gray-400">{{ txt.configDevider }}</div>
			<button
				class="group relative block w-full select-none rounded-lg border-2 border-dashed border-gray-300 px-6 pt-5 pb-6 text-center transition-colors hover:border-gray-400 hover:text-primary-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-400 focus-visible:ring-offset-2"
				@click="useFile()"
				type="button"
			>
				<div class="transition-transform group-hover:scale-105">
					<div class="mx-auto h-12 w-12">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 48 48"
							stroke="currentColor"
							stroke-width="2"
							fill="none"
							aria-hidden="true"
						>
							<path
								d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</div>
					<span class="text-md mt-2 block text-inherit"> {{ txt.uploadFile }} </span>
				</div>
			</button>
		</div>
		<div class="flex justify-center pt-12">
			<SwitchGroup
				as="div"
				class="-ml-6 flex select-none items-center"
			>
				<Switch
					v-model="enabled"
					:class="[enabled ? 'bg-primary-400' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer select-none rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2']"
				>
					<span
						aria-hidden="true"
						:class="[enabled ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']"
					/>
				</Switch>
				<SwitchLabel
					as="span"
					class="ml-4 cursor-pointer"
				>
					<p class="select-none text-sm text-gray-500 transition-colors hover:text-gray-800">{{ txt.useMicText }}</p>
				</SwitchLabel>
			</SwitchGroup>
		</div>
	</div>
</template>

<script>
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";

export default {
	components: {Switch, SwitchGroup, SwitchLabel},
	data() {
		return {
			enabled: false
		}
	},
	methods: {
		setMic(val) {
			console.log(val);
		},
		useFile() {
			this.$emit("useFile");
		},
		useRecord() {
			this.$emit("useRecord");
		},
	},
	watch: {
		enabled(val) {
			this.$emit("setMic", val);
		},
	},
};
</script>
