import { createApp } from "vue";
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { createStore } from "./store";
import "animate.css";
import "./styles/index.css";

const store = createStore();

const app = createApp({
	render: (h) => h(App),

	created() {
		console.log("ENV:", process.env.NODE_ENV);
		this.$store.dispatch("setBackend");
		this.$store.dispatch("setLang");
	},
	...App,
});

//init Axios
app.config.productionTip = false;
app.use(
	VueAxios,
	axios.create({
		timeout: 30000,
	})
);

app.mixin({
	computed: {
		txt() {
			return this.$store.state.txt;
		},
		backendUrl() {
			return this.$store.state.backendUrl;
		},
	},
	methods: {
		delay(time) {
			return new Promise((resolve) => setTimeout(resolve, time));
		},
	},
});

app.use(store);

app.mount("#app");
