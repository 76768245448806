<!-- [ ] if pressing cancel when choosing what to share, go home -->
<!-- [ ] if leaving page, stop recording -->

<template>
	<div class="flex items-center justify-center py-8">
		<div v-if="doingRecord || doneRecording">
			<div>
				<div v-if="doingRecord">
					<button
						class="btn-primary"
						@click="stopRecording()"
					>
						<div class="animate__animated animate__flash animate__infinite animate__slow -ml-2 pr-2">
							<i class="las la-square text-3xl" />
						</div>
						{{ txt.btnStopRecording }}
					</button>
				</div>
				<div v-else>Afventer...</div>
			</div>
		</div>
		<div v-else>{{ txt.waiting }}</div>
	</div>
</template>

<script>
let RecordRTC = require("recordrtc");
export default {
	props: ["mic"],
	data() {
		return {
			doingRecord: false,
			doneRecording: false,
			recording: null,
			stream: null,
		};
	},
	mounted() {
		this.initRecording();
	},
	beforeUnmount() {
		if (this.doingRecord) {
			this.recordRTC.stopRecording();
			this.stream.stop();
			this.recordRTC.destroy();
		}
	},
	methods: {
		async startRecording(stream) {
			document.title = this.txt.recordingTabTitle;
			var options = {
				mimeType: "video/webm;codecs=vp9", // or video/webm\;codecs=h264 or video/webm\;codecs=vp9
				type: "video",
			};
			this.stream = stream;

			this.addListeners();
			if (this.mic) {
				console.log("Requesting microphone...")
				let micSource = await navigator.mediaDevices.getUserMedia({ audio: true });
				stream.addTrack(micSource.getTracks()[0]);
			}

			this.recordRTC = RecordRTC(stream, options);
			this.recordRTC.startRecording();
			this.doingRecord = true;
		},
		cancelRecording() {
			this.$emit("back");
		},
		processVideo(webM) {
			//just emit to parent
			this.$emit("next", this.recordRTC.getBlob(), webM);

			//stop the stream
			console.log("webM", webM);
			this.stream.stop();
			this.recordRTC.destroy();
			// document.title = this.txt.tabTitle;
		},
		initRecording() {
			//clear previous recording data
			this.recording = null;
			this.$emit("updateWarning", true);

			let mediaConstraints = {
				video: {
					cursor: "always",
				},
				audio: true,
			};

			//take older version of chrome into account
			if (navigator.mediaDevices.getDisplayMedia) {
				//new way
				navigator.mediaDevices.getDisplayMedia(mediaConstraints).then(this.startRecording.bind(this), this.cancelRecording.bind(this));
			} else {
				//old way
				navigator.getDisplayMedia(mediaConstraints).then(this.startRecording.bind(this), this.cancelRecording.bind(this));
			}
		},
		stopRecording() {
			console.log("stop called");
			this.recordRTC.stopRecording(this.processVideo.bind(this));
			this.doingRecord = false;
			this.doneRecording = true;

			document.title = this.txt.tabTitle;
		},
		addListeners() {
			//add listeners for when the stream stops externally
			this.stream.addEventListener("ended", this.stopRecording, false);
			this.stream.addEventListener("inactive", this.stopRecording, false);
		},
	},
	watch: {
		stream(val) {
			console.log("stream: ", val);
		},
		doingRecord(val) {
			this.$emit("updateWarning", val);
		},
	},
};
</script>
