import { createStore as _createStore } from "vuex";

export function createStore() {
	return _createStore({
		state: {
			txt: {
				// main app
				tabTitle: "Screen recording & file upload",
				title: "Screen recording & file upload",
				privacyTitle: "Privacy policy",
				privacyText: `We take good care of your data.`,
				privacyLink: {
					href: "https://www.supporters.dk/privatlivspolitik/",
					text: "Read more here",
				},
				invalidCaseID: "Invalid case ID, please contact support",
				linkUsed: "Sorry, but this link has already been used",

				// config
				configDevider: "OR",
				useMicText: "Record microphone audio",
				startRecording: "Start recording",
				uploadFile: "Upload file",

				// recording
				waiting: "Awaiting choice...",
				recording: "Recording",
				recordingTabTitle: "Recording...",

				// file
				filesToUpload: "Files to upload",
				preparing: "Preparing",
				noFilesAddedTitle: "No files added",
				noFilesAddedText: "Press the 'Add file' button below to add one or multiple files.",

				// upload
				unknownError: "An error has occured. Please try again later.",
				uploadErrorTitle: "Error!",
				uploadErrorText: "Hmm.. This is wierd. The page isn't working right now, please notify us.",
				uploadingTitle: "Uploading",
				uploadedTitle: "Uploaded!",
				uploadedRecordingText: "Your recording has been sent.",
				uploadedFileText: "Your file has been sent.",
				uploadedFilesText: "Your files have been sent.",
				closeThisPage: "You can safely close this page now.",

				// modal
				warnTitle: "Are you sure you want to go back to the start?",
				warnText: "All progress will be lost.",

				// buttons
				btnSend: "Send",
				btnDownload: "Download",
				btnStopRecording: "Stop recording",
				btnBack: "Back",
				btnRetake: "Retake",
				btnRestart: "Restart",
				btnAddFile: "Add files",
				btnUpload: "Upload",
				btnCancel: "Cancel",
				btnConfirm: "Confirm",

				// file types
				imageType: "Image",
			},
			backendUrl: "https://supporters-uploader.azurewebsites.net/api/",
		},
		mutations: {},
		actions: {
			setLang(store) {
				if (window.navigator.language == "da-DK") {
					//DK language
					console.log("Danish detected. Injecting...");
					store.state.txt = {
						// main app
						tabTitle: "Skærmoptagelse & filupload",
						title: "Skærmoptagelse & filupload",
						privacyTitle: "Privatlivspolitik",
						privacyText: `Vi passer godt på dine data.`,
						privacyLink: {
							href: "https://www.supporters.dk/privatlivspolitik/",
							text: "Læs mere her",
						},
						invalidCaseID: "Ugyldigt sagsnr. kontakt venligst support",
						linkUsed: "Dette link er allerede blevet brugt.",

						// config
						configDevider: "ELLER",
						useMicText: "Optag mikrofon lyd",
						startRecording: "Start optagelse",
						uploadFile: "Upload fil",

						// recording
						waiting: "Afventer handling...",
						recording: "Optager",
						recordingTabTitle: "Optager...",

						// file
						filesToUpload: "Filer til upload",
						preparing: "Forbereder",
						noFilesAddedTitle: "Ingen filer tilføjes",
						noFilesAddedText: "Tryk på 'Tilføj filer' for at tilføje en eller flere filer.",

						// upload
						unknownError: "En fejl er opstået. Prøv venligst igen senere.",
						uploadErrorTitle: "Fejl!",
						uploadErrorText: "Hmm... Det var underligt. Siden virker ikke lige nu, anmeld det venligst, hurtigst muligt.",
						uploadingTitle: "Uploader",
						uploadedTitle: "Uploadet!",
						uploadedRecordingText: "Din optagelse er blevet sendt.",
						uploadedFileText: "Din fil er blevet sendt.",
						uploadedFilesText: "Dine filer er blevet sendt.",
						closeThisPage: "Du kan lukke siden nu.",

						// modal
						warnTitle: "Er du sikker på at du vil gå tilbage til starten?",
						warnText: "Dine ændringer vil blive mistet.",

						// buttons
						btnSend: "Send",
						btnDownload: "Download",
						btnStopRecording: "Stop recording",
						btnBack: "Tilbage",
						btnRetake: "Genoptag",
						btnRestart: "Genstart",
						btnAddFile: "Tilføj filer",
						btnUpload: "Upload",
						btnCancel: "Cancel",
						btnConfirm: "Confirm",

						// file types
						imageType: "Billede",
					};
				} else {
					//anything else than danish
					console.log("Other language detected. Keeping defaults");
				}
			},
			setBackend(store) {
				if (process.env.NODE_ENV == "development") {
					console.log("Using dev.");
					store.state.backendUrl = "http://localhost:7071/api";
				}
			},
		},
		modules: {},
	});
}
