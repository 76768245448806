<template>
	<!-- <Dialog
		:open="showModal"
		as="div"
		class="relative z-10"
	>
		<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

		<div class="fixed inset-0 z-10 overflow-y-auto">
			<div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
				<DialogPanel class="absolute top-0 transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
					<div class="sm:flex sm:items-start">
						<div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
							<DialogTitle
								as="h3"
								class="text-xl"
								>{{ txt.warnTitle }}
							</DialogTitle>
							<div class="mt-2">
								<p class="text-base">{{ txt.warnText }}</p>
							</div>
						</div>
					</div>
					<div class="mt-4 flex justify-end gap-4">
						<button
							type="button"
							class="btn-ghost"
							@click="_confirm"
						>
							Confirm
						</button>
						<button
							type="button"
							class="btn-primary"
							@click="_cancel"
							ref="cancelButtonRef"
						>
							Cancel
						</button>
					</div>
				</DialogPanel>
			</div>
		</div>
	</Dialog> -->
	<div
		v-if="showModal"
		class="fixed z-[9999] flex"
	>
		<div class="fixed inset-x-0 top-0 z-[1] mx-auto mt-8 flex max-w-lg flex-col rounded-md bg-white px-8 pt-8 pb-7">
			<div>
				<h3 class="text-lg">{{ txt.warnTitle }}</h3>
			</div>
			<div class="mt-2">
				<p>{{ txt.warnText }}</p>
			</div>
			<div class="mt-6 flex justify-end gap-4">
				<button
					class="btn-ghost"
					@click="_confirm"
				>
					{{ txt.btnConfirm }}
				</button>
				<button
					class="btn-primary"
					@click="_cancel"
				>
					{{ txt.btnCancel }}
				</button>
			</div>
		</div>
		<div class="fixed inset-0 bg-gray-500 bg-opacity-75" />
	</div>
</template>

<script>
export default {
	data() {
		return {
			showModal: false,
			showOnLoad: false,

			resolvePromise: undefined,
			rejectPromise: undefined,
		};
	},
	mounted() {
		if (this.showOnLoad) {
			this.show();
		}
	},
	methods: {
		closeModal() {
			this.showModal = false;
		},
		openModal() {
			this.showModal = true;
		},
		show() {
			this.openModal();

			return new Promise((resolve, reject) => {
				this.resolvePromise = resolve;
				this.rejectPromise = reject;
			});
		},
		_confirm() {
			this.closeModal();
			this.resolvePromise(true);
		},
		_cancel() {
			this.closeModal();
			this.resolvePromise(false);
		},
	},
};
</script>
