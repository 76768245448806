<!-- [X] icon for restart btn -->
<!-- [X] icon for download btn -->
<!-- [X] icon for send btn -->

<template>
	<div class="-mt-6 flex flex-col items-center justify-center">
		<video
			ref="video"
			class="rounded-md"
			autoplay
			controls
		/>
		<div class="mt-10 flex w-full flex-row">
			<button
				class="btn-ghost justify-self-start"
				@click="$emit('back')"
			>
				<div class="-ml-2 pr-2">
					<i class="la la-redo-alt text-2xl" />
				</div>
				{{ txt.btnRetake }}
			</button>
			<div class="ml-auto flex gap-8">
				<button
					class="btn-ghost"
					@click="download"
				>
					<div class="-ml-2 pr-2">
						<i class="la la-download text-2xl" />
					</div>
					{{ txt.btnDownload }}
				</button>
				<button
					class="btn-primary"
					@click="$emit('upload')"
				>
					<div class="-ml-2 pr-2">
						<i class="la la-send text-2xl" />
					</div>
					{{ txt.btnSend }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { saveAs } from "file-saver";

export default {
	props: ["blob", "webM", "caseID", "number"],
	data() {
		return {};
	},
	mounted() {
		console.log(this.blob);
		this.$refs.video.src = this.webM;

		this.$emit("updateWarning", true);
	},
	methods: {
		download() {
			saveAs(this.blob, `${this.caseID}_${this.number}`);
		},
	},
};
</script>
